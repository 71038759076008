import React, {Component} from 'react';
import Amplify, { API, graphqlOperation } from 'aws-amplify';
import * as queries from './graphql/queries';
import { Connect } from "aws-amplify-react";

class Me extends Component {

  render() {

    const ListView = ({ todos }) => (
               <div>
                   <h3>Users</h3>
                   <ul>
                       {todos.map(todo => <li key={todo.id}>{todo.firstName} {todo.lastName}</li>)}
                   </ul>
               </div>
           );

    return (<Connect query={graphqlOperation(queries.listVolunteers)}>
                {({ data: { listVolunteers }, loading, errors }) => {
                    if (errors && errors.length > 0) {
                      console.log(errors);
                      return (<h3>Error</h3>);
                    }
                    if (loading || !listVolunteers) {
                      return (<h3>Loading...</h3>);
                    }
                    return (<ListView todos={listVolunteers.items} /> );
                }}
            </Connect>);
  }

}

export default Me;
