import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import WarningIcon from '@material-ui/icons/Warning';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import './Certifications.css';
import Media from './Media';
import Carp from './Carp';
import * as moment from 'moment';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    padding: 0,
    backgroundColor: theme.palette.background.paper,
  },
}));

function isExpired(date) {

  if(!date) {
    return true;
  }

  let now = moment();
  return moment(date).isBefore(now);
}

export default function Certifications(props) {
  const theme = useTheme();
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  function handleChange(event, newValue) {
    setValue(newValue);
  }

  function updateVolunteer(volunteer) {
    if(props.updateVolunteer) {
      props.updateVolunteer(volunteer);
    }
  }

  return (
    <div className={classes.root}>
      <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
        <Tab label="Sierra Club" icon={isExpired(props.volunteer.sierraExpires) ? <WarningIcon color="secondary"/> : <VerifiedUserIcon color="primary"/>} {...a11yProps(0)} />
        <Tab label="CARP" icon={isExpired(props.volunteer.carpExpires) ? <WarningIcon color="secondary"/> : <VerifiedUserIcon color="primary"/>} {...a11yProps(1)} />
        <Tab label="First Aid" icon={isExpired(props.volunteer.firstAidExpires) ? <WarningIcon color="secondary"/> : <VerifiedUserIcon color="primary"/>} {...a11yProps(2)} />
        <Tab label="CPR" icon={isExpired(props.volunteer.cprExpires) ? <WarningIcon color="secondary"/> : <VerifiedUserIcon color="primary"/>} {...a11yProps(3)} />
        <Tab label="NLT" icon={isExpired(props.volunteer.leaderExpires) ? <WarningIcon color="secondary"/> : <VerifiedUserIcon color="primary"/>} {...a11yProps(4)} />
        <Tab label="Background Check" icon={isExpired(props.volunteer.backgroundExpires) ? <WarningIcon color="secondary"/> : <VerifiedUserIcon color="primary"/>} {...a11yProps(5)} />
      </Tabs>
      <TabPanel value={value} index={0}>
        <h3>{isExpired(props.volunteer.sierraExpires) ? "Expired:" : "Expires:"} {new Date(props.volunteer.sierraExpires).toLocaleDateString()}</h3>
       <Media category="sierra_club"></Media>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <h3>{isExpired(props.volunteer.carpExpires) ? "Expired:" : "Expires:"} {new Date(props.volunteer.carpExpires).toLocaleDateString()}</h3>
        <Carp updateVolunteer={updateVolunteer}/>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <h3>{isExpired(props.volunteer.firstAidExpires) ? "Expired:" : "Expires:"} {new Date(props.volunteer.firstAidExpires).toLocaleDateString()}</h3>
        <Media category="first_aid"></Media>
      </TabPanel>
      <TabPanel value={value} index={3}>
        <h3>{isExpired(props.volunteer.cprExpires) ? "Expired:" : "Expires:"} {new Date(props.volunteer.cprExpires).toLocaleDateString()}</h3>
        <Media category="cpr"></Media>
      </TabPanel>
      <TabPanel value={value} index={4}>
        <h3>{isExpired(props.volunteer.leaderExpires) ? "Expired:" : "Expires:"} {new Date(props.volunteer.leaderExpires).toLocaleDateString()}</h3>
        New Leader Training is held once a year the weekend after laborday. However we sometimes have a mid year NLT refresher. When that is announced dates will be posted here.
      </TabPanel>
      <TabPanel value={value} index={5}>
        <h3>{isExpired(props.volunteer.backgroundExpires) ? "Expired:" : "Expires:"} {new Date(props.volunteer.backgroundExpires).toLocaleDateString()}</h3>
        <a href="mailto:support@seattleico.freshdesk.com?subject=I%20Require%20A%20New%20Background%20Check&amp;body=I,%20Your%20full%20name%20consent%20to%20a%20background%20check">Email Seattle ICO Records</a>
      </TabPanel>
    </div>
  );
}
