import Amplify, { Storage } from 'aws-amplify';
import React, { Component } from 'react';
import { S3Image, AmplifyTheme } from 'aws-amplify-react';
import AwsPhotoPicker from './AwsPhotoPicker';
import './AwsImage.css';

class AwsImage extends S3Image  {
  constructor(props) {
    super(props);
  }

  render() {
		const { hidden, style, picker, translate, imgKey } = this.props;
		let src = this.state.src;
		if (translate) {
			src =
				typeof translate === 'string'
					? translate
					: translate({
							type: 'image',
							key: imgKey,
							content: src,
					  });
		}
		if (!src && !picker) {
			return null;
		}

		const theme = this.props.theme || AmplifyTheme;
		const photoStyle = hidden
			? AmplifyTheme.hidden
			: Object.assign({}, theme.photo, style);

		return (
			<div className="awsPhoto" style={photoStyle}>
				{photoStyle ? this.imageEl(src, theme) : null}
				{picker ? (
					<div>
						<AwsPhotoPicker title={this.props.title} key="picker" onPick={this.handlePick} theme={theme} />
					</div>
				) : null}
			</div>
		);
	}
}

export default AwsImage;
