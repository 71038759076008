import Amplify, { Storage } from 'aws-amplify';
import React, { Component } from 'react';
import AwsImage from './AwsImage';

class Media extends Component  {
  constructor(props) {
    super(props);

    Storage.configure({ level: 'protected' });

    this.fileToKey = this.fileToKey.bind(this);

  }

  fileToKey(data) {
    return this.props.category;
  }

  render() {
    return (<div><AwsImage title="Select an image" imgKey={this.props.category} level="private" picker fileToKey={this.fileToKey} /></div>);
  }
}

export default Media;
