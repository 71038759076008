import React, { Component } from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import './VolunteerInfo.css';
import TextField from '@material-ui/core/TextField';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

class VolunteerInfo extends Component {

  constructor(props) {
    super(props);

    this.getTeams = this.getTeams.bind(this);
  }

  getTeams() {

    if(!this.props.volunteer || !this.props.volunteer.teams) {
      return null;
    }

    return this.props.volunteer.teams.map(t => t.name).join(' & ')
  }

  render() {

    return (
      <div className="root" style={{width:"80%"}} >
        <h2 style={{margin: "20px"}}>Teams: {this.getTeams()}</h2>
        <FormGroup>
          <FormGroup row>
            <FormControlLabel
              control={
                <Switch
                  checked={this.props.volunteer.active}
                  value="checkedB"
                  color="primary"
                />
              }
              label="Active Volunteer"
            />
          </FormGroup>
          <FormGroup row>
          <TextField
            required
            label="Email"
            defaultValue={this.props.volunteer.email}
            margin="normal"
            variant="outlined"
          />
          </FormGroup>
          <FormGroup row>
            <TextField
              required
              style={{width:"45%"}}
              label="First Name"
              defaultValue={this.props.volunteer.nameFirst}
              margin="normal"
              variant="outlined"
            />
            <TextField
              required
              style={{width:"45%"}}
              label="Last Name"
              defaultValue={this.props.volunteer.nameLast}
              margin="normal"
              variant="outlined"
            />
          </FormGroup>
          <FormGroup row>
            <TextField
              style={{width:"90%"}}
              required
              label="Street Address"
              defaultValue={this.props.volunteer.street}
              margin="normal"
              variant="outlined"
            />
          </FormGroup>
          <FormGroup row>
            <TextField
              style={{width:"30%"}}
              required
              label="City"
              defaultValue={this.props.volunteer.city}
              margin="normal"
              variant="outlined"
            />
            <TextField
              style={{width:"30%"}}
              required
              label="State"
              defaultValue={this.props.volunteer.stateOrProvince}
              margin="normal"
              variant="outlined"
            />
            <TextField
              style={{width:"30%"}}
              required
              label="Zip Code"
              defaultValue={this.props.volunteer.postalCode}
              margin="normal"
              variant="outlined"
            />
          </FormGroup>
        </FormGroup>
        <FormGroup>
          <FormGroup row>
            <TextField
              required
              label="Emergency Contact"
              defaultValue={this.props.volunteer.emergencyContact}
              margin="normal"
              variant="outlined"
              style={{width:"90%"}}
            />
          </FormGroup>
        </FormGroup>
    </div>
    );
  }
}

export default VolunteerInfo;
