import Quiz from 'react-quiz-component';
import React, {Component} from 'react';
import Button from '@material-ui/core/Button';
import SaveButton from './SaveButton';
import { API } from 'aws-amplify';

import { quiz } from './CarpQuiz';

class Carp extends Component {

  constructor(props) {
    super(props);

    this.state = {
      quiz: quiz,
      saving: false
    }

    this.renderCustomResultPage = this.renderCustomResultPage.bind(this);
    this.saveToRoster = this.saveToRoster.bind(this);
  }


  componentDidUpdate(nextProps) {
    if(this.state.quiz == null) {
      this.setState({quiz: quiz});
    }
  }

  saveToRoster(callback) {

    this.setState({saving: true})

    let apiName = 'updateRecord';
    let path = '/records';

    API.post(apiName, path, {body: {carp: true}})
    .then(async (response) => {
      if(response.success) {
        if(this.props.updateVolunteer) {
          this.props.updateVolunteer(response.success);
        }
        callback(true);
      } else {
        callback(false);
      }
    }).catch(error => {
      callback(false);
    });
  }

  renderCustomResultPage(obj) {
    console.log(obj);

    let correct = obj.numberOfCorrectAnswers;
    let questions = obj.numberOfQuestions;

    console.log(`q: ${questions}, c: ${correct}, a: ${(questions / correct)}`);

    let passed = false;

    if(correct != 0) {
      if(questions / correct >= 0.9) {
        passed = true;
      }
    }

    return (
      <div>
        {passed &&
          <div>
            <div>You have passed the assessment.</div>
            <SaveButton variant="contained" color="primary" title="Save to Roster"  saving={ (callback) => { this.saveToRoster(callback); }} />
          </div>
        }
        {!passed &&
          <div>
            <div>You did not answer enough questions correctly. Please read study the curriculum and retake the assessment. </div>
            <Button variant="contained" color="primary"  onClick={ () => { this.setState({quiz: null}); }}>Retake Quiz</Button>
          </div>
        }
      </div>
    );
  }

  render() {
    return(<Quiz quiz={this.state.quiz} showDefaultResult={false} shuffle={true} customResultPage={this.renderCustomResultPage} continueTillCorrect={true} showInstantFeedback={true}/>)
  }
}

export default Carp;
