import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import Button from '@material-ui/core/Button';
import VolunteerInfo from './VolunteerInfo';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import ContactsIcon from '@material-ui/icons/Contacts';
import UpdateIcon from '@material-ui/icons/Update';
import Badge from '@material-ui/core/Badge';
import Certifications from './Certifications';
import FolderSharedIcon from '@material-ui/icons/FolderShared';
import Amplify, { API, graphqlOperation, Auth } from 'aws-amplify';
import CircularProgress from '@material-ui/core/CircularProgress';
import * as moment from 'moment';

const drawerWidth = 240;


let v = {
  "street": "19027 37th Ave S",
  "city": "Seatac",
  "stateOrProvince": "WA",
  "postalCode": "98188",
  "phone": "425-577-4257",
  "email": "erniebooth@gmail.com",
  "emergencyContact": "Stephanie Booth (206) 858-3289 or Laura Mitchell (425)-772-7323",
  "active": true,
  "isSteering": true,
  "isWebAdmin": true,
  "icoRoles": null,
  "connection": {
    "id": 3,
    "name": "ICO Leader"
  },
  "sierraClubId": "50686558",
  "sierraExpires": "2019-09-20T00:00:00",
  "firstAidExpires": "2022-10-30T00:00:00",
  "cprExpires": "2022-10-30T00:00:00",
  "carpExpires": "2020-09-08T00:00:00",
  "leaderSince": "2017-09-09T00:00:00",
  "leaderExpires": "2022-09-09T00:00:00",
  "backgroundExpires": "2021-06-09T00:00:00",
  "otherTraining": null,
  "dateCreated": "2017-08-02T23:21:46",
  "dateUpdated": "2019-09-15T21:08:31",
  "id": 661,
  "nameLast": "Booth",
  "nameFirst": "Ernie",
  "teams": [
    {
      "id": 21,
      "name": "Washington"
    }
  ]
};

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: 0,
  },
}));

function isExpired(date) {

  if(!date) {
    return true;
  }

  let now = moment();
  return moment(date).isBefore(now);
}

function expiredCount(v) {

  if(!v) {
    return 6;
  }

  let count = 0;

  if(isExpired(v.sierraExpires)) { count++; }
  if(isExpired(v.carpExpires)) { count++; }
  if(isExpired(v.firstAidExpires)) { count++; }
  if(isExpired(v.cprExpires)) { count++; }
  if(isExpired(v.leaderExpires)) { count++; }
  if(isExpired(v.backgroundExpires)) { count++; }

  return count;
}

export default function MiniDrawer() {

  function getVolunteerRecord() {
    let apiName = 'updateRecord';
    let path = '/records';

    setLoading(true);

    API.get(apiName, path)
    .then(response => {
      setLoading(false);
      setVolunteer(response);
      setNotificationCount([expiredCount(response), 0, 0]);
    }).catch(error => {
      setLoading(true);
    });
  }

  useEffect(() => {
    getVolunteerRecord();
  }, []);

  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(['Certifications', 'Contact Information', 'History']);
  const [selectedIndex, setSelected] = useState(0);
  const [volunteer, setVolunteer] = useState({});
  const [notificationCount, setNotificationCount] = useState([0, 0, 0]);
  const [isLoading, setLoading] = useState(false);

  function updateVolunteer(volunteer) {
    setVolunteer(volunteer);
    setNotificationCount([expiredCount(volunteer), 0, 0]);
  }

  function handleDrawerOpen() {
    setOpen(true);
  }

  function handleDrawerClose() {
    setOpen(false);
  }

  function handleSelection(index) {
    setSelected(index);
  }

  if(isLoading) {
    return (<CircularProgress  style={{marginLeft: "50%"}}/>);
  }

  if(!volunteer) {
    return(<div style={{margin: "10%"}}>No volunteer found with this email address. Please make sure you signup with the same email you use at <a href="https://volunteers.seattleico.org">https://volunteers.seattleico.org</a></div>);
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap>
            Seattle ICO - {page[selectedIndex]}
          </Typography>
          <Button onClick={() => {Auth.signOut()}} color="inherit" style={{ position: "fixed", right: "20px"}}>Logout</Button>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
        open={open}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </div>
        <Divider />
        <List>
          {[...page].map((text, index) => (
            <ListItem button key={text} selected={index % 3 === selectedIndex} onClick={event => handleSelection(index)}>
              <ListItemIcon><Badge badgeContent={notificationCount[index]} color="secondary">{index % 3 === 1 ? <ContactsIcon /> : index % 3 === 0 ? <VerifiedUserIcon /> : <UpdateIcon/>}</Badge></ListItemIcon>
              <ListItemText primary={text} />
            </ListItem>
          ))}
        </List>
      </Drawer>
      <main className={classes.content}>

        {selectedIndex == 0 &&
          <Certifications updateVolunteer={updateVolunteer} volunteer={volunteer}></Certifications>
        }
        {selectedIndex == 1 &&
          <VolunteerInfo volunteer={volunteer}></VolunteerInfo>
        }
      </main>
    </div>
  );
}
