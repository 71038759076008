// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_content_delivery_bucket": "ico-20190830112904-hostingbucket-ico",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://d21qhi1ivbfqs3.cloudfront.net",
    "aws_cognito_identity_pool_id": "us-east-1:5f9b094c-0893-4883-a6de-e36f41c86434",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_HBriTUmnw",
    "aws_user_pools_web_client_id": "3ei7m1cq3b7ieam9dqshova4ln",
    "oauth": {},
    "aws_mobile_analytics_app_id": "641c80f3ec3849378a36de4767605b8a",
    "aws_mobile_analytics_app_region": "us-east-1",
    "aws_user_files_s3_bucket": "icorecordsdata-ico",
    "aws_user_files_s3_bucket_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://wbj75yu5bjdenc3rjznd2qjt3a.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cloud_logic_custom": [
        {
            "name": "updateRecord",
            "endpoint": "https://z51338b1w2.execute-api.us-east-1.amazonaws.com/ico",
            "region": "us-east-1"
        }
    ]
};


export default awsmobile;
