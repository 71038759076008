import React from 'react';
import logo from './logo.svg';
import './App.css';
import Amplify from 'aws-amplify';
import awsconfig from './aws-exports';
import Me from './Me';
import VolunteerInfo from './VolunteerInfo';
import Main from './Main';
import { withAuthenticator, Authenticator } from 'aws-amplify-react';

Amplify.configure(awsconfig);

function App() {
  return (
      <Authenticator
      usernameAttributes='email'>
        <Main></Main>
      </Authenticator>
    );
}

export default withAuthenticator(App, { usernameAttributes: 'email' });
