import Amplify, { Storage } from 'aws-amplify';
import React, { Component } from 'react';
import { PhotoPicker, AmplifyTheme, PickerPreview, Picker } from 'aws-amplify-react';
import * as UIComponents from 'aws-amplify-react/dist/Amplify-UI/Amplify-UI-Components-React';

class AwsPhotoPicker extends PhotoPicker  {
  constructor(props) {
    super(props);
  }

  render() {
		const { preview } = this.props;
		const { previewSrc } = this.state;

		const headerText = this.props.headerText || 'Photos';
		const headerHint =
			this.props.headerHint || 'Add your photos by clicking below';
		const title = this.props.title || 'Select a Photo';

		const theme = this.props.theme || AmplifyTheme;
		const previewStyle = Object.assign({}, PickerPreview, theme.pickerPreview);

		const previewHidden = !(preview && preview !== 'hidden');

		return (
		    <div style={{width: "100px"}}>
          <div>
    					{previewSrc ? (
    						previewHidden ? (
    							'The image has been selected'
    						) : (
    							<img src={previewSrc} style={previewStyle} />
    						)
    					) : (
    						<div></div>
    					)}
          </div>
  				<Picker
  					title={title}
  					accept="image/*"
  					theme={theme}
  					onPick={this.handlePick}
  				/>
        </div>
		);
	}
}

export default AwsPhotoPicker;
