export const quiz =  {
  "quizTitle": "Child Abuse Recognition and Prevention Assessment",
  "quizSynopsis": "Before taking quiz please review Child Abuse Recognition and Prevention curriculum on our volunteer website. https://volunteers.seattleico.org/documents/training/CARPCurriculum_20171115184107171.doc",
  "questions": [
    {
      "question": "The Code of Ethics",
      "questionType": "text",
      "answerSelectionType": "single",
      "answers": [
        "a.	Lists unacceptable actions such as smoking, drinking, using drugs, having pornography and carrying weapons.",
        "b.	Stipulates that a leader may not abuse participants or leaders, must conduct themselves in a courteous and respectful manner, and use positive techniques of guidance and encouragement.",
        "c.	States that leaders should not be alone with participants and should refrain from intimate displays of affection.",
        "d.	All of the above"
      ],
      "correctAnswer": "4",


      "point": "6.25"
    },
    {
      "question": "What are the leader screening steps an ICO/youth outings leader needs to take (select all that apply)?",
      "questionType": "text",
      "answerSelectionType": "multiple",
      "answers": [
        "a.	Fill out an application (ICO Volunteer Application/Restricted Youth Outings Application).",
        "b.	Fill out a code of ethics (ICO: and a Volunteer Agreement)",
        "c. Have a criminal background check done.",
        "d.	Submit to an IRS audit.",
        "e.	Have a DMV check done if driving",
        "f.	Complete the Child Abuse Prevention and Recognition training."
      ],
      "correctAnswer": [1,2,3,5,6],
      "messageForCorrectAnswer": "Correct answer. Good job.",

      "point": "6.25"
    },
    {
      "question": "How often must an ICO/youth outings leader get a criminal background check?",
      "questionType": "text",
      "answerSelectionType": "single",
      "answers": [
        "a.	Once.",
        "b.	Every year.",
        "c.	Every two years.",
        "d.	Every 4 years."
      ],
      "correctAnswer": "4",
      "messageForCorrectAnswer": "Correct answer.",

      "point": "6.25"
    },
    {
      "question": "What is the purpose of the ICO volunteer Application/Restricted Youth Outings Application?",
      "questionType": "text",
      "answerSelectionType": "multiple",
      "answers": [
        "a.	Gather information about the leader’s experience and motivation for leading youth-oriented outings.",
        "b.	To create more paperwork.",
        "c.	Obtain personal information to then obtain a criminal background check and DMV check (if driving).",
      ],
      "correctAnswer": [1,3],
      "messageForCorrectAnswer": "Correct answer. Good job.",

      "point": "6.25"
    },
    {
      "question": "According to Sierra Club policy, on an ICO trip, who has the responsibility to report suspected child abuse?",
      "questionType": "text",
      "answerSelectionType": "single",
      "answers": [
        "a.	The ICO leader.",
        "b.	Other participants.",
        "c.	The agency representative on the outing.."
      ],
      "correctAnswer": "1",
      "messageForCorrectAnswer": "Correct answer.",

      "point": "6.25"
    },
    {
      "question": "The reporting procedures for suspected child abuse on an ICO trip are: to immediately call the National Sierra Club Office to begin the formal reporting process, and complete a Sierra Club Incident Report.",
      "questionType": "text",
      "answerSelectionType": "single",
      "answers": [
        "a.	True.",
        "b.	False"
      ],
      "correctAnswer": "1",
      "messageForCorrectAnswer": "Correct answer.",

      "point": "6.25"
    },
    {
      "question": "What are the reporting procedures for suspected child abuse on a non-ICO youth oriented outing?",
      "questionType": "text",
      "answerSelectionType": "single",
      "answers": [
        "a.	Inform your chair in the end-of-trip report",
        "b.	Ignore it.",
        "c.	Immediately report the suspected abuse to the National Sierra Club Office and complete a Sierra Club Incident Report."
      ],
      "correctAnswer": "3",
      "messageForCorrectAnswer": "Correct answer.",

      "point": "6.25"
    },
    {
      "question": "Which of the following will help you set a positive tone and prevent any false allegations of abuse?",
      "questionType": "text",
      "answerSelectionType": "single",
      "answers": [
        "a.	Supervise any private activities with youth in pairs (and ideally in view of others).",
        "b.	Watching for signs of stress in self/other leaders in order to maintain a safe environment.",
        "c.	Setting boundaries with youth participants and fellow leaders.",
        "d.	All of the above."
      ],
      "correctAnswer": "4",
      "messageForCorrectAnswer": "Correct answer.",

      "point": "6.25"
    },
    {
      "question": "Inappropriate behavior on a youth outing includes:",
      "questionType": "text",
      "answerSelectionType": "single",
      "answers": [
        "a.	Full body hugs.",
        "b.	Wrestling and tickling matches.",
        "c.	Taking naps together.",
        "d.	Swearing and off-color jokes.",
        "e.	Smoking and drinking.",
        "f. all of the above"
      ],
      "correctAnswer": "6",
      "messageForCorrectAnswer": "Correct answer.",

      "point": "6.25"
    },
    {
      "question": "Which of the following may be considered appropriate touch (select all that apply):",
      "questionType": "text",
      "answerSelectionType": "multiple",
      "answers": [
        "a.	Lightly and briefly patting a child’s arm or back.",
        "b.	Giving a massage.",
        "c.	Giving a side-hug (if requested).",
        "d.	Slapping a child’s bottom.",
        "e.	Gently ruffling or combing of a child’s hair.",
        "f.	Having a child sit on your lap.",
        "g.	Holding hands or interlocking arms (especially around an activity)."
      ],
      "correctAnswer": [1,3,7],
      "messageForCorrectAnswer": "Correct answer.",

      "point": "6.25"
    },
    {
      "question": "What are the four different types of child abuse to be aware of?",
      "questionType": "text",
      "answerSelectionType": "multiple",
      "answers": [
        "a.	Alcohol.",
        "b.	Sexual.",
        "c.	Drug.",
        "d.	Neglect.",
        "e.	Physical.",
        "f.	Emotional."
      ],
      "correctAnswer": [2,4,5,6],
      "messageForCorrectAnswer": "Correct answer.",

      "point": "6.25"
    },
    {
      "question": "Match the scenario with possible type of abuse: You notice several 1x4 inch bruises (purple and green) on a child’s back and thighs while supervising swimming in the lake. They may be suffering from?",
      "questionType": "text",
      "answerSelectionType": "single",
      "answers": [
        "a.	Sexual.",
        "b.	Neglect.",
        "c.	Physical.",
        "d.	Emotional."
      ],
      "correctAnswer": "3",
      "messageForCorrectAnswer": "Correct answer.",

      "point": "6.25"
    },
    {
      "question": "Match the scenario with possible type of abuse:	A youth participant who shows up for your outing with no shoes and tattered clothing, has a really strong body odor, and appears really tired. They may be suffering from?",
      "questionType": "text",
      "answerSelectionType": "single",
      "answers": [
        "a.	Sexual.",
        "b.	Neglect.",
        "c.	Physical.",
        "d.	Emotional."
      ],
      "correctAnswer": "2",
      "messageForCorrectAnswer": "Correct answer.",

      "point": "6.25"
    },
    {
      "question": "Match the scenario with possible type of abuse:	A normally outgoing participant comes to you complaining of “itching down there.” You also notice that they are not comfortable sitting. They may be suffering from?  ",
      "questionType": "text",
      "answerSelectionType": "single",
      "answers": [
        "a.	Sexual.",
        "b.	Neglect.",
        "c.	Physical.",
        "d.	Emotional."
      ],
      "correctAnswer": "1",
      "messageForCorrectAnswer": "Correct answer.",

      "point": "6.25"
    },
    {
      "question": "Match the scenario with possible type of abuse:	A youth participant is abiding by all the trip rules and letting you know about it; they seem overly eager to please.  You know that it is “rough” at home for them.  They could be suffering from?  ",
      "questionType": "text",
      "answerSelectionType": "single",
      "answers": [
        "a.	Sexual.",
        "b.	Neglect.",
        "c.	Physical.",
        "d.	Emotional."
      ],
      "correctAnswer": "4",
      "messageForCorrectAnswer": "Correct answer.",

      "point": "6.25"
    },
    {
      "question": "If you have a child disclosing abuse to you, you should ",
      "questionType": "text",
      "answerSelectionType": "multiple",
      "answers": [
        "a.	Be calm.",
        "b.	Ask very detailed leading questions of the participant.",
        "c.	Write down exactly what was said.",
        "d.	Promise them that everything will be ok and this will remain confidential.",
        "e.	Assure the child that the abuse was not his/her fault and that it was right to tell you.",
        "f.	Report this to the ICO agency personnel associated with the outing."
      ],
      "correctAnswer": [1,3,5,6],
      "messageForCorrectAnswer": "Correct answer.",

      "point": "6.25"
    }
  ]
};
